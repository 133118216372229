'use strict'

angular
  .module 'mundoAdmin.home'
  # .config ($stateProvider) ->
  #   $stateProvider
  #     .state 'adminhome',
  #       url: '/home'
  #       parent: 'admin'
  #       views:
  #         '@admin':
  #           templateUrl: 'mundo-admin/home/views/home.tpl.html'
  #           controller: 'AdminHomeCtrl'
  #           controllerAs: 'adminhome'

